<template>
  <div class="about-us-bg">
    <div class="plan">
      <div class="left">
        <img src="../assets/images/20210218-18.webp" alt="" />
      </div>
      <div class="right">
        <div class="icon1"><p class="icon"></p></div>
        <p class="title">{{ $t("AboutUs.title") }}</p>
        <div :class="lang === 'cn' ? 'content' : 'content content2'">
          <p class="txt" v-html="$t('AboutUs.txt')"></p>
        </div>
      </div>
    </div>
    <p class="line"></p>
    <p class="icon"></p>
    <p class="title" style="margin: 0px auto">{{ $t("AboutUs.tit2") }}</p>
    <div class="objective">
      <div class="left">
        <p class="count">1</p>
        <p class="txt">{{ $t("AboutUs.tit2_txt1") }}</p>
      </div>
      <!-- <div class="sj1"></div> -->
      <div class="center">
        <p class="count">2</p>
        <p class="txt">{{ $t("AboutUs.tit2_txt2") }}</p>
      </div>
      <!-- <div class="sj2"></div> -->
      <div class="right">
        <p class="count">3</p>
        <p class="txt">{{ $t("AboutUs.tit2_txt3") }}</p>
      </div>
    </div>
    <div class="why">
      <div class="left">
        <div class="left-con">
          <br />
          <p class="title" style="margin: 0">{{ $t("AboutUs.tit3") }}</p>
          <div class="line"></div>
          <br /><br />
          <div class="tick tick1"></div>
          <p class="txt">{{ $t("AboutUs.tit3_txt1") }}</p>
          <br />
          <div class="tick tick2"></div>
          <p class="txt">{{ $t("AboutUs.tit3_txt2") }}</p>
          <br />
          <div class="tick tick3"></div>
          <p class="txt">{{ $t("AboutUs.tit3_txt3") }}</p>
        </div>
      </div>
      <div class="right">
        <div class="top"></div>
        <div class="bottom"></div>
      </div>
    </div>
    <div class="qualification">
      <div class="left">
        <img src="../assets/images/20210218-49.webp" alt="" />
      </div>
      <div class="right">
        <div class="right-con">
          <p class="title" style="margin: 0">{{ $t("AboutUs.tit4") }}</p>
          <div class="line"></div>
          <br />
          <div class="tick"></div>
          <p class="txt" v-html="$t('AboutUs.tit4_txt1')"></p>
          <div class="tick"></div>
          <p class="txt">{{ $t("AboutUs.tit4_txt2") }}</p>
        </div>
      </div>
    </div>
    <div class="line" style="margin: 60px auto"></div>

    <div class="create">
      <div class="title create-tit">
        <div class="round">
          <p class="small-round"></p>
          <p class="big-round"></p>
        </div>
        {{ $t("AboutUs.tit5") }}
      </div>
      <div class="create-con">
        <div class="left"></div>
        <div class="right">
          <br />
          {{ $t("AboutUs.tit5_txt") }}
        </div>
      </div>
    </div>
    <div class="create" style="margin-top: -50px">
      <div class="title create-tit">
        <div class="round">
          <p class="small-round"></p>
          <p class="big-round"></p>
        </div>
        {{ $t("AboutUs.tit6") }}
      </div>
      <div class="create-con">
        <div class="left left1"></div>
        <div class="right">
          <br />
          {{ $t("AboutUs.tit6_txt") }}
        </div>
      </div>
    </div>
    <!-- <div class="strategy"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      image1: "../assets/images/Group 11815.png",
      image2: "../assets/images/Group 11816.png",
      lang: window.localStorage.getItem("locale") || "cn",
    };
  },
};
</script>

<style scoped lang="scss">
@import "../style/enStyle.scss";
.about-us-bg {
  .icon {
    width: 40px;
    height: 40px;
    margin: 40px auto 5px;
    background: url("../assets/images/Group 11846.svg") 50%/50% no-repeat;
  }
  .icon1 {
    width: 77%;
    height: 40px;
    text-align: center;
  }
  .line {
    width: 65%;
    background-color: #d2d2d2;
    height: 1px;
    margin: 0px auto 40px;
  }
  .title {
    width: 77%;
    // background: red;
    font-size: 24px;
    color: #1e30f6;
    // font-weight: 600;
    letter-spacing: 2px;
    margin: 5px 0 0 0;
    font-family: "PingFang-Heavy";
    line-height: 24px;
  }
  .plan {
    display: flex;
    margin: 40px auto;
    .left {
      flex: 1;
      img {
        width: 100%;
        height: 100%;
        border-radius: 40px;
      }
    }
    .right {
      flex: 1;

      .content {
        width: 70%;
        height: 300px;
        margin: 35px 0 0 0;
        position: relative;
        background: url("../assets/images/Group 11815.png") no-repeat 100%/110%
          92%;
        // background: blue;
        .txt {
          width: 350px;
          position: absolute;
          line-height: 28px;
          text-align: left;
          left: 95px;
          top: 64px;
        }
      }
      .content2 {
        background: url("../assets/images/Group 11816.png") no-repeat 100%/105%
          92%;
      }
    }
  }
  .objective {
    width: 75%;
    min-height: 240px;
    background: #fff;
    margin: 30px auto;
    border-radius: 50px 20px 50px 20px;
    box-shadow: #efecef 2px 2px 8px 2px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .left,
    .center,
    .right {
      width: 25%;
      height: 150px;
      position: relative;
      border-bottom: 5px solid #2138ed;
      .count {
        height: 100%;
        float: left;
        color: #6ec9f5;
        font-size: 52px;
        // font-weight: 600;
        margin-right: 15px;
      }
      .txt {
        line-height: 24px;
        padding-top: 8px;
        text-align: left;
        letter-spacing: 1px;
        font-family: "PingFang-Bold";
      }
    }
    .sj1 {
      position: absolute;
      top: 70px;
      left: 340px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left: 10px solid #6ec9f5;
    }
    .sj2 {
      position: absolute;
      top: 70px;
      left: 695px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left: 10px solid #6ec9f5;
    }
  }
  .why {
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin: 60px auto;

    .left {
      width: 70%;
      // background: lightcoral;
      position: relative;
      .left-con {
        margin-left: 10%;
        text-align: left;
        width: 80%;
        // background: red;
        line-height: 30px;
      }
      .txt {
        margin-left: 9%;
        letter-spacing: 1px;
      }
      .line {
        // position: absolute;
        width: 40px;
        height: 5px;
        background: #6ec9f5;
        border-radius: 10px;
        float: left;
        margin-top: 16px;
      }
      .tick {
        margin-top: 5px;
        width: 32px;
        height: 32px;
        background: url("../assets/images/Path 11812.png") no-repeat 100%/100%;
        position: absolute;
      }
      .tick1 {
        // top: 0px;
        // left: 0px;
      }
    }
    .right {
      width: 30%;
      height: 550px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      // background: chocolate;
      .top {
        flex: 1;
        width: 100%;
        height: 200px;
        background: url("../assets/images/CYFANPHOTO-InspiringHK-WeOlympic-Part2-124.png")
          no-repeat 100%/100%;
      }
      .bottom {
        flex: 1;
        width: 100%;
        height: 300px;
        background: url("../assets/images/20200627-300.png") no-repeat 100%/100%;
      }
    }
  }
  .qualification {
    position: relative;
    height: 300px;
    // background: olive;
    .left {
      position: absolute;
      top: 17%;
      left: 14%;
      width: 37%;
      height: 180px;
      // background: url("") no-repeat 100%/100%;
      z-index: 2;
      // background: red;
      transform: skewX(-30deg);
      overflow: hidden;
      border-radius: 0 15px 0 15px;
      img {
        width: 125%;
        height: 220%;
        transform: skewX(30deg) translate(0%, -40%);
      }
    }
    .right {
      position: absolute;
      right: 12%;
      width: 57%;
      height: 280px;
      border-radius: 20px;
      box-shadow: #efecef 2px 2px 8px 2px;
      background: #fff;
      .right-con {
        // position: absolute;
        // top: 0px;
        // left:50%;
        margin-top: 3%;
        text-align: left;
        float: right;
        width: 53%;
        // background: red;
        line-height: 30px;
        .line {
          width: 40px;
          height: 5px;
          background: #6ec9f5;
          border-radius: 10px;
          float: left;
          margin-top: 12px;
        }
        .txt {
          padding: 4% 6% 0 14%;
        }
        .tick {
          margin-top: 20px;
          width: 32px;
          height: 32px;
          background: url("../assets/images/Path 11783.png") no-repeat 100%/100%;
          position: absolute;
        }
      }
    }
  }
  .create {
    margin: 0px auto 100px;
    width: 65%;
    .create-tit {
      text-align: left;
      width: 100%;
      color: #535353;
    }
    .create-con {
      color: #707070;
      display: flex;
      // justify-content: center;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      margin-top: 20px;
      letter-spacing: 1px;
      .left {
        margin-left: 5%;
        width: 20%;
        height: 100px;
        background: url("../assets/images/ihk-logo.47be4f54.svg") no-repeat 100%/100%;
        transform: translateY(-10px);
      }
      .left1 {
        background: url("../assets/images/image.png") no-repeat 100%/100%;
        transform: translateY(-25px);
      }
      .right {
        width: 60%;
        line-height: 26px;
        text-align: left;
        font-family: "PingFang-Medium";
      }
    }
  }
  .round {
    width: 30px;
    height: 30px;
    float: left;
    // background: red;
    position: relative;
    margin-right: 10px;
    .small-round {
      position: absolute;
      left: 6px;
      bottom: 6px;
      width: 6px;
      height: 6px;
      background: #6ec9f5;
      border-radius: 50%;
    }
    .big-round {
      top: 6px;
      right: 6px;
      position: absolute;
      width: 12px;
      height: 12px;
      background: #6ec9f5;
      border-radius: 50%;
    }
  }
}
@media screen and (max-width: 520px) {
  .about-us-bg {
    .icon1,
    .title {
      width: 100%;
    }
    .plan {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .left,
      .right {
        width: 90%;
        height: auto;
        flex: none;
        .content {
          width: 100%;
          background: url("../assets/images/Group 11815.png") no-repeat 100%/124%
            100%;
          .txt {
            width: 90%;
            left: 12px;
            top: 50px;
          }
        }
              .content2 {
        background: url("../assets/images/Group 11816.png") no-repeat 100%/118% 100%;
      }
      }
    }
    .objective {
      width: 90%;
      flex-direction: column;
      padding: 5% 0;
      .left,
      .center,
      .right {
        width: 80%;
        height: auto;
        padding-bottom: 5%;
        .txt {
          float: right;
          width: 80%;
        }
      }
      .sj1,
      .sj2,
      .sj3 {
        display: none;
      }
    }
    .why {
      width: 90%;
      flex-direction: column;
      margin: 60px auto 0;
      .left,
      .right {
        width: 100%;
        .txt {
          margin-left: 18%;
        }
      }
      .left {
        .left-con {
          margin-left: 0;
          width: 100%;
        }
      }
    }
    .qualification {
      .left {
        top: 0%;
        left: 0%;
        width: 100%;
      }
      .right {
        top: 60%;
        right: 0%;
        width: 100%;
        height: auto;
        .right-con {
          margin: 3% 0;
          width: 90%;
        }
      }
    }
    .create {
      margin: 50% auto;
      width: 90%;
      .create-con {
        flex-direction: column;
        .left {
          margin-left: 0;
          width: 45%;
        }
        .right {
          width: 100%;
        }
        .left1 {
          transform: translateY(0);
          width: 50%;
        }
      }
    }
    .create:last-child {
      margin: -40% auto 0;
    }
  }
}
</style>